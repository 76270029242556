import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import LOVE from "./glb/untitled_28(1).glb";
import { Float } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF(LOVE);
  return (
    <group position={[0, -2, 0]} {...props} dispose={null}>
      <Float>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.brush_MatteHull} />
            <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry} material={materials.brush_Icing} />
            <mesh castShadow receiveShadow geometry={nodes.Object_9.geometry} material={materials.brush_Flat} />
            <mesh castShadow receiveShadow geometry={nodes.Object_12.geometry} material={materials.brush_MatteHull} />
            <mesh castShadow receiveShadow geometry={nodes.Object_14.geometry} material={materials.brush_Icing} />
            <mesh castShadow receiveShadow geometry={nodes.Object_17.geometry} material={materials.brush_Petal} />
            <mesh castShadow receiveShadow geometry={nodes.Object_20.geometry} material={materials.brush_Icing} />
            <mesh castShadow receiveShadow geometry={nodes.Object_22.geometry} material={materials.brush_Lofted} />
          </group>
        </group>
      </Float>
    </group>
  );
}

useGLTF.preload(LOVE);
