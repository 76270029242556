import "./App.css";
import { useState, useEffect } from "react";
import Question from "./Question";
import ThreeCanvas from "./Three";

function App() {
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    if (finish) console.log("fini!!!");
  }, [finish]);

  return (
    <div>
      <Question setFinish={setFinish} rightAnswer={"love"} question={"I'am your personal deposit area for random stuff"} />
      <Question rightAnswer={"meow"} question={"I'm 30% scarf, 100% crazy animal"} />
      <Question rightAnswer={"kaffee"} question={"It keeps me warm, and I'm so faaaancy with it <3"} />
      <Question rightAnswer={"chouchou"} question={"I still spark joy even though I'm totally dehydrated"} />
      <Question rightAnswer={"ouch"} question={"I don't need a frame to make this place looks better"} />
      <Question rightAnswer={"choupinoupi"} question={"Every evening and morning I watch you clean the ghosts"} />
      <Question rightAnswer={"schatzeli"} question={"It all started with it, but we never listened to it together"} />
      <Question
        rightAnswer={"all right baby"}
        question={
          "Welcome to the virtual love room. To get your present, you'll have to find all the passwords in the house. Don't worry, I'll help you :) If you are ready, write \"all right baby\" and touch the love"
        }
      />
      {finish && <ThreeCanvas />}
    </div>
  );
}

export default App;
