import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Cloud, Sparkles, Sky } from "@react-three/drei";
import { Model } from "./Model";
import chet from "./mp3/chet.mp3";

export default function ThreeCanvas() {
  const audio = new Audio(chet);
  audio.play();
  return (
    <div className="canvas">
      <Canvas camera={{ position: [45, 0.5, -0] }}>
        <OrbitControls maxDistance={45} enablePan={false} position={[0, 4, 4]} />
        <ambientLight intensity={0.5} />
        <directionalLight intensity={0.5} color="white" position={[-4, 10, 9]} />
        <directionalLight intensity={0.5} color="white" position={[1, 10, -9]} />
        <directionalLight intensity={0.5} color="white" position={[-4, -10, 9]} />
        <directionalLight intensity={0.5} color="white" position={[1, -10, -9]} />
        <Sparkles position={[0, -0.5, 0]} count={300} scale={[3, 3, 3]} />
        <Sky
          mieDirectionalG={0.47}
          rayleigh={1.9}
          mieCoefficient={0.0008}
          distance={450000}
          sunPosition={[-4, 0.001, 0]}
          inclination={100}
          azimuth={180}
          turbidity={20}
        />
        <Cloud
          position={[0, -6, 0]}
          opacity={0.5}
          speed={0.4} // Rotation speed
          width={2} // Width of the full cloud
          depth={2} // Z-dir depth
          segments={10} // Number of particles
        />

        {/* <directionalLight color="red" position={[0, 0, 5]} /> */}
        <Model />
      </Canvas>
    </div>
  );
}
