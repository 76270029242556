import { useState } from "react";
import kiss from "./mp3/kiss.mp3";

export default function Question({ question, rightAnswer, setFinish }) {
  const [answer, setAnswer] = useState("");
  const [display, setDisplay] = useState("flex");

  const kissSound = new Audio(kiss);

  const submitAnswer = (e) => {
    e.preventDefault();
    if (rightAnswer.toLowerCase() === answer.toLowerCase()) {
      kissSound.play();
      setDisplay("none");
      if (answer.toLowerCase() == "love") {
        setFinish(true);
      }
    }
  };

  return (
    <div className="question" style={{ display: display }}>
      <div className="wrapper">
        <form onSubmit={(e) => submitAnswer(e)}>
          <p>{question}</p>
          <input
            type="text"
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          />
          <br />
          <button type="submit">
            <svg className="heart" viewBox="0 0 32 29.6">
              <path
                d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
              />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
}
